import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import Banner from "@components/banners/Ai";
import ActivitiesArea from "../containers/research-activities"

const ActivitiesPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <Seo title="Research Activities" />
    <Header />
    <Banner
			pageContext={pageContext}
			location={location}
			title="Research Activities"
		/>
    <main className="site-wrapper-reveal">
      <ActivitiesArea />
    </main>
    <Footer />
  </Layout>
)

export default ActivitiesPage
