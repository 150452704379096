import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import Heading from "@ui/heading"
import List, { ListItem } from "@ui/list"
import { SectionWrap } from "./style"
// import Dot from "@ui/divider/dot"
import Line from "@ui/divider/line";
import SectionTitle from "@ui/section-title"

const ActivitiesArea = ({ sectionTitleStyle, ListThreeStyle }) => {
  const getActivitiesData = useStaticQuery(graphql`
    query GET_Activities_DATA {
      pubs: researchActivitiesJson {
              pubs {                
                journal
                journallink
                link
                title
                conference
                type
                volume
                year
                location
                project
                grants
                role
                members
              }
        }
    }
  `)
  const {
    pubs: { pubs },
  } = getActivitiesData

  const type_list = pubs.map(pub => {
    return pub.type
  })
  const types = [...new Set(type_list)]

  function check_is_null(pubs, type) {
    var i = 0
    pubs.map(pub => {
      if (pub.type.includes(type)) {
        i++
      }
    })
    return i
  }
  var m = 0
  function empty_return(m){
    if (m != 0){
      return (
            <Row>
            <Col lg={12}>
              <SectionTitle
                {...sectionTitleStyle}
                subtitle="Oops, it seems to be empty here..."
              />
            </Col>
          </Row>
          )
        }
  }
  const textStyle = {
    color:"#6d6fb0",
    display: "unset",
  }

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            {types.map(type => {
              if (check_is_null(pubs, type) != 0) {
                return (
                  <Row>
                    <Col lg={12}>
                      <Heading as="h6" mb="15px">
                        {type}
                      </Heading>
                      <List {...ListThreeStyle}>
                        {pubs.map(pub => {
                          if (pub.type===type) {
                            return (
                              <font size="2">
                                <ListItem>
                                  {pub.year}.
                                  <a
                                    display="inline"
                                    href={pub.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={textStyle}
                                  >                                  
                                  <b> {pub.conference} </b>
                                  </a>
                                  <span
                                    style={{fontStyle: 'italic'}}
                                  >
                                    {pub.project}
                                  </span>
                                  <span
                                    style={{fontStyle: 'italic'}}
                                  >
                                    {pub.title}
                                  </span>
                                  <span
                                    style = {textStyle}
                                  ><b> {pub.journal} </b>
                                  </span>,
                                  {pub.volume}
                                  {pub.grants}
                                  {pub.role}
                                  {pub.members}
                                  {pub.location}.
                                </ListItem>
                              </font>
                            )
                          }
                        })}
                      </List>
                      {/* <Dot mt="40px" /> */}
                      <Line mt="40px" mb="40px" borderStyle="dashed" />
                    </Col>
                  </Row>
                )
              }
              else {
                m++
              }
            })}
            {empty_return(m)}
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

ActivitiesArea.defaultProps = {
  ListThreeStyle: {
    layout: "circle",
  },
  sectionTitleStyle: {
    mb: "30px",
    responsive: {
      small: {
        mb: "20px",
      },
    },
  },
}

export default ActivitiesArea